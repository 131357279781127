<template>
  <div class="wap_con">
    <div class="con_con">
      <!-- logo -->
      <el-image
        :src="require(`@/assets/img/wapdownload/${logoImgPath}.png`)"
        alt=""
        class="wap_logo"
      />
      <!-- 二维码 -->
      <div class="qrcode_con">
        <div class="qrcode_l">
          <el-image
            :src="require(`@/assets/download/${iosDownPath}.png`)"
            class="qrcode_img"
          />
          <a :href="ios">
            <el-image
              :src="require('@/assets/img/wapdownload/ios_download_btn.png')"
              class="app_btn"
            />
          </a>
        </div>
        <div class="qrcode_r">
          <el-image
            :src="require(`@/assets/download/${androidDownPath}.png`)"
            class="qrcode_img"
          />
          <el-image
            v-if="isWxClient && isMobileTerminal"
            :src="require('@/assets/img/wapdownload/android_download_btn.png')"
            class="app_btn"
            @click="showGuid"
          />
          <a v-else :href="android_cdn">
            <el-image
              :src="
                require('@/assets/img/wapdownload/android_download_btn.png')
              "
              class="app_btn"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ios: {
      type: String,
      default: "",
    },
    android_cdn: {
      type: String,
      default: "",
    },
    isWxClient: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isMobileTerminal: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    type: {
      type: String,
      default: "0",
    },
  },
  methods: {
    showGuid() {
      this.$emit("showGuid");
    },
  },
  computed: {
    logoImgPath() {
      return this.type === "0" ? "az_haohuotong_logo" : "az_quanqiugou_logo";
    },
    iosDownPath() {
      return this.type === "0" ? "app_ios_d" : "app_ios";
    },
    androidDownPath() {
      return this.type === "0" ? "app_android_d_2023_02_23" : "app_android";
    },
  },
};
</script>

<style lang="less" scoped>
.wap_con {
  padding: 70px 24px 0;
  background: #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.con_con {
  background: #fff;
  border-radius: 12px;
  padding: 83px 0 97px;
}
.wap_logo {
  display: block;
  width: 150px;
  margin: 0 auto;
}
.qrcode_con {
  // position: absolute;
  // top: 83px;
  // bottom: 97px;
  margin-top: 84px;
  display: flex;
  justify-content: center;
  .qrcode_l,
  .qrcode_r {
    text-align: center;
  }

  .qrcode_r {
    margin-left: 25px;
  }

  .qrcode_img {
    width: 80px;
  }

  .app_btn {
    margin-top: 30px;
    width: 123px;
    display: block;
  }
}
</style>
